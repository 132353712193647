import { API, handleApiError } from "./utils";

const path = "members";

export const fetchMembers = async () => {
  try {
    const res = await API.get(`/${path}/fetch-members`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return { error: null, data: res.data };
  } catch (error) {
    return handleApiError(error);
  }
};