import { memo, useState } from "react";
import { Link } from "react-router-dom";
import { HiOutlineHome, HiOutlineChartBar, HiOutlineUsers } from "react-icons/hi2";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { TbClick } from "react-icons/tb";
import { CiSettings } from "react-icons/ci";
import { SlPresent } from "react-icons/sl";
import {useSelector} from "react-redux";
import {LockClosedIcon} from "@heroicons/react/solid";

const HOME = "home";
const CAMPAIGNS = "campaigns";
const ANALYTICS = "analytics";
const EVENTS = "events";
const LOYALTY_STORE  = "loyalty_store";
const SETTINGS = "settings";
const MEMBERS = "members";
const USERS = "users";

const Leftbar = () => {
    const [selectedItem, setSelectedItem] = useState("home");
    const currentOrganisation = useSelector((state) => state.organisation?.currentOrganisation);

    const transformSelectedItem = (newSelectedItem) => {
        let item = null;
        if (newSelectedItem) {
            item = newSelectedItem.toLowerCase();
        }
        setSelectedItem(item);
    }

    return (
        <div className="leftbar bg-white text-gray-900 h-screen w-16 md:w-64 flex flex-col items-center py-4 border-r border-gray-200">
            <div className="flex flex-col items-center md:items-start w-full gap-4 px-2 md:px-5">
                {/*<Link*/}
                {/*    onClick={() => {*/}
                {/*        transformSelectedItem(HOME);*/}
                {/*    }}*/}
                {/*    className={`flex items-center gap-2 w-full py-2 px-4 rounded-md ${selectedItem === HOME ? "bg-gray-100" : "hover:bg-gray-100"} transition duration-200`}*/}
                {/*    to="/home">*/}
                {/*    <HiOutlineHome className="text-2xl" />*/}
                {/*    <span className="hidden md:block">Home</span>*/}
                {/*</Link>*/}
                <Link
                    onClick={() => {
                        transformSelectedItem(CAMPAIGNS);
                    }}
                    className={`flex items-center gap-2 w-full py-2 px-4 rounded-md ${selectedItem === CAMPAIGNS ? "bg-gray-100" : "hover:bg-gray-100"} transition duration-200`}
                    to="/campaigns">
                    <HiOutlineSpeakerphone className="text-2xl" />
                    <span className="hidden md:block">Campaigns</span>
                </Link>
                <Link
                    onClick={() => {
                        transformSelectedItem(MEMBERS);
                    }}
                    className={`flex items-center gap-2 w-full py-2 px-4 rounded-md ${selectedItem === MEMBERS ? "bg-gray-100" : "hover:bg-gray-100"} transition duration-200`}
                    to="/members">
                    <HiOutlineUsers className="text-2xl" />
                    <span className="hidden md:block">Members</span>
                </Link>
                {currentOrganisation?.settings?.shop?.isVerified ?
                    <a
                        className={`flex items-center gap-2 w-full py-2 px-4 rounded-md ${selectedItem === LOYALTY_STORE ? "bg-gray-100" : "hover:bg-gray-100"} transition duration-200`}
                        target="_blank" href={currentOrganisation?.settings?.shop?.customShopDomain}>
                        <SlPresent className="text-2xl"/>
                        <span className="hidden md:block">Loyalty Store</span>
                    </a>
                    :
                    <p
                        className={`cursor-pointer flex items-center gap-2 w-full py-2 px-4 rounded-md ${selectedItem === LOYALTY_STORE ? "bg-gray-100" : "hover:bg-gray-100 opacity-50"} transition duration-200`}>
                        <SlPresent className="text-2xl"/>
                        <span className="hidden md:block">Loyalty Store</span>
                        <LockClosedIcon className="ms-2 w-6"/>
                    </p>
                }
                <Link
                    onClick={() => {
                        transformSelectedItem(EVENTS);
                    }}
                    className={`flex items-center gap-2 w-full py-2 px-4 rounded-md ${selectedItem === EVENTS ? "bg-gray-100" : "hover:bg-gray-100 opacity-50"} transition duration-200`}
                    to="/events">
                    <TbClick className="text-2xl"/>
                    <span className="hidden md:block">Events</span>
                </Link>
                <Link
                    onClick={() => {
                        transformSelectedItem(ANALYTICS);
                    }}
                    className={`flex items-center gap-2 w-full py-2 px-4 rounded-md ${selectedItem === ANALYTICS ? "bg-gray-100" : "hover:bg-gray-100 opacity-50"} transition duration-200`}
                    to="/analytics">
                    <HiOutlineChartBar className="text-2xl" />
                    <span className="hidden md:block">Analytics</span>
                </Link>
                <hr className="w-full border-gray-300 my-2" />
                <Link
                    onClick={() => {
                        transformSelectedItem(SETTINGS);
                    }}
                    className={`flex items-center gap-2 w-full py-2 px-4 rounded-md ${selectedItem === SETTINGS ? "bg-gray-100" : "hover:bg-gray-100"} transition duration-200`}
                    to="/settings">
                    <CiSettings className="text-2xl" />
                    <span className="hidden md:block">Settings</span>
                </Link>
            </div>
        </div>
    );
};

export default memo(Leftbar);
