import { useState } from 'react';

function ApiKeyComponent(props) {
    const [copied, setCopied] = useState(false);

    const copyToClipboard = (event) => {
        event.preventDefault(); // Prevents any default action like navigation
        navigator.clipboard.writeText(props.apiKey).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
        }).catch((error) => {
            console.error('Failed to copy text: ', error);
        });
    };

    return (
        <div>
            <h3 className="text-xl font-medium text-gray-900">API Key</h3>
            <div className="mt-2">
                <input
                    id="api-key"
                    name="api-key"
                    type="text"
                    value={props.apiKey}
                    readOnly
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <button
                    onClick={copyToClipboard}
                    className="mt-2 px-4 py-2 bg-primary text-white rounded-md shadow-sm hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500"
                >
                    {copied ? "Copied!" : "Copy Key"}
                </button>
            </div>
        </div>
    );
}

export default ApiKeyComponent;
