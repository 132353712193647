import * as api from "../api/memberAPI";
import * as types from "../constants/memberConstants";

export const fetchMembersAction = () => async (dispatch) => {
  try {
    const { error, data } = await api.fetchMembers();

    if (error) {
      throw new Error(error);
    }

    dispatch({
      type: types.FETCH_MEMBERS,
      payload: data,
    });
  } catch (error) {
    // dispatch({
    //   type: types.GET_USER_FAIL,
    //   payload: error.message,
    // });
  }
};