import * as api from "../api/organisationAPI";
import * as types from "../constants/organisationConstants";
import {SHOP_DOMAIN_VERIFIED} from "../constants/organisationConstants";

export const fetchOrganisationsNameAndIdAction = (userId) => async (dispatch) => {
  try {
    const { error, data } = await api.fetchOrganisationsNameAndId(userId);

    if (error) {
      throw new Error(error);
    }

    dispatch({
      type: types.FETCH_ORGANISATIONS,
      payload: data,
    });

    dispatch(fetchSingleOrganisationAction(data.organisations[0]._id))
  } catch (error) {
    // dispatch({
    //   type: types.GET_USER_FAIL,
    //   payload: error.message,
    // });
  }
};


export const fetchSingleOrganisationAction = (organisationId) => async (dispatch) => {
  try {
    const { error, data } = await api.fetchSingleOrganisation(organisationId);

    if (error) {
      throw new Error(error);
    }

    dispatch({
      type: types.FETCH_SINGLE_ORGANISATION,
      payload: data,
    });
  } catch (error) {
    // dispatch({
    //   type: types.GET_USER_FAIL,
    //   payload: error.message,
    // });
  }
};