import React from 'react';

const Feature = (props) => {
    return (
        <div className="flex padding-vertical">
            <div className={`flex ${props.left ? "flex-row" : "flex-row-reverse"}`}>
                <div>
                    <img
                        src="https://assets-global.website-files.com/6364d8cb4443c41b196eab02/65291032168ccacfd31eb96d_Dekra%20ISO%20certificates%20box%203x.webp"
                        loading="lazy"
                        width="1440"
                        sizes="(max-width: 479px) 93vw, (max-width: 767px) 80vw, (max-width: 991px) 81vw, 38vw"
                        alt="ISO and GDPR certificates."
                        srcSet="https://assets-global.website-files.com/6364d8cb4443c41b196eab02/65291032168ccacfd31eb96d_Dekra%20ISO%20certificates%20box%203x-p-500.webp 500w, https://assets-global.website-files.com/6364d8cb4443c41b196eab02/65291032168ccacfd31eb96d_Dekra%20ISO%20certificates%20box%203x-p-800.webp 800w, https://assets-global.website-files.com/6364d8cb4443c41b196eab02/65291032168ccacfd31eb96d_Dekra%20ISO%20certificates%20box%203x-p-1080.webp 1080w, https://assets-global.website-files.com/6364d8cb4443c41b196eab02/65291032168ccacfd31eb96d_Dekra%20ISO%20certificates%20box%203x-p-1600.webp 1600w, https://assets-global.website-files.com/6364d8cb4443c41b196eab02/65291032168ccacfd31eb96d_Dekra%20ISO%20certificates%20box%203x.webp 1680w"
                    />
                </div>
                <div id="w-node-_8d51e68a-3483-59b2-63f0-fb9401ae27cd-821a1078" className="layout192-1_content">
                    <div className="margin-bottom margin-xsmall">
                        <div className="text-weight-semibold text-color-primary600 text-align-left">Enterprise-grade security and quality</div>
                    </div>
                    <div className="margin-bottom margin-xsmall">
                        <h2 className="heading-medium text-color-gray900">We place the highest priority on security and quality</h2>
                    </div>
                    <div className="margin-bottom margin-xsmall">
                        <div className="text-size-medium">Data security and quality assurance are a top priority at Open Loyalty. That’s why we follow all applicable guidelines and policies to ensure the most reliable data security and quality management policies to ensure the highest standards.</div>
                    </div>
                    <div className="text-size-medium">ISO 27001 and ISO 9001 along with GDPR compliant prove our words and commitment.<br /></div>
                </div>
            </div>
        </div>
    );
};

export default Feature;
