import {Suspense} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Routes, Route, Navigate} from "react-router-dom";
import FallbackLoading from "./components/loader/FallbackLoading";
import {publicRoutes, privateRoutes} from "./routes";

import PrivateRoute from "./PrivateRoute";
import SignIn from "./pages/SignIn";
import {fetchOrganisationsNameAndIdAction} from "./redux/actions/organisationActions";


const App = () => {
    const userData = useSelector((state) => state.auth?.userData);
    const organisations = useSelector((state) => state.organisation?.organisations);
    const dispatch = useDispatch();

    if(userData && organisations.length === 0) {
        dispatch(fetchOrganisationsNameAndIdAction(userData._id))
    }

    return (
        <Suspense fallback={<FallbackLoading/>}>
            <Routes>
                <Route element={<PrivateRoute userData={userData}/>}>
                    {privateRoutes.map((route) => (
                        <Route key={route.path} path={route.path} element={route.element}/>
                    ))}
                </Route>

                {publicRoutes.map((route) => (
                    <Route key={route.path} path={route.path} element={route.element}/>
                ))}

                <Route
                    path="/signin"
                    element={userData ? <Navigate to="/campaigns"/> : <SignIn/>}
                />
                <Route path="/" element={<Navigate to="/campaigns" replace/>}/>
            </Routes>
        </Suspense>
    );
};

export default App;
