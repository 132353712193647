import * as api from "../api/campaignAPI";
import * as organisationTypes from "../constants/organisationConstants";

export const createCampaignAction = (ruleBuilder) => async (dispatch, getState) => {
  try {
    const currentOrganisationId = getState().organisation.currentOrganisation._id;
    const { error, data } = await api.createCampaign(ruleBuilder, currentOrganisationId);

    if (error) {
      throw new Error(error);
    }

    dispatch({
      type: organisationTypes.ADD_CAMPAIGN,
      payload: data,
    });

  } catch (error) {
    // dispatch({
    //   type: types.GET_USER_FAIL,
    //   payload: error.message,
    // });
  }
};

export const updateCampaignAction = (ruleBuilder) => async (dispatch, getState) => {
  try {
    const currentOrganisationId = getState().organisation.currentOrganisation._id;
    const { error, data } = await api.updateCampaign(ruleBuilder, currentOrganisationId);

    if (error) {
      throw new Error(error);
    }

    dispatch({
      type: organisationTypes.UPDATE_CAMPAIGN,
      payload: data,
    });
  } catch (error) {
    // dispatch({
    //   type: types.GET_USER_FAIL,
    //   payload: error.message,
    // });
  }
};

export const deleteCampaignAction = (id) => async (dispatch, getState) => {
  try {
    const currentOrganisationId = getState().organisation.currentOrganisation._id;
    const { error, data } = await api.deleteCampaign(id, currentOrganisationId);

    if (error) {
      throw new Error(error);
    }

    dispatch({
      type: organisationTypes.DELETE_CAMPAIGN,
      payload: data,
    });
  } catch (error) {
    // dispatch({
    //   type: types.GET_USER_FAIL,
    //   payload: error.message,
    // });
  }
};

export const submitAction = (data) => async (dispatch, getState) => {
  const currentOrganisationId = getState().organisation.currentOrganisation._id;

  const submission = {
    memberData: {
      identification: data.identification,
      name: data.name,
    },
    submissionData: {
      campaignId: data.campaignId,
      value: data.value
    },
    apiKey: "2f8c0f46957a243ae2b3f1e1ad6b687e12e4694a5f5f7d9d7a0b68d74a1b8f23"
  }

  try {
    const { error, data } = await api.submit(submission, currentOrganisationId);

    if (error) {
      throw new Error(error);
    }

    dispatch({
      type: organisationTypes.UPDATE_ORGANISATION,
      payload: data,
    });
  } catch (error) {
    // dispatch({
    //   type: types.GET_USER_FAIL,
    //   payload: error.message,
    // });
  }
};