import {API, handleApiError} from "./utils";

const path = "organisations";

export const fetchOrganisationsNameAndId = async (userId) => {
  try {
    const res = await API.get(`/${path}/fetch-organisations?userId=${userId}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return { error: null, data: res.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const fetchSingleOrganisation = async (organisationId) => {
  try {
    const res = await API.get(`/${path}/fetch-single-organisation?organisationId=${organisationId}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return { error: null, data: res.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const verifyAndSaveDomain = async (domain, organisationId) => {
  try {
    return await API.post(`/${path}/verify-save-domain`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {domain, organisationId},
    })
  } catch (error) {
    return null
  }
}
