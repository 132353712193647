import React, { useState } from 'react';

export const MembersTable = (props) => {
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredMembers = props.members?.filter(member => {
        return (
            member.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            member.identification.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    return (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <div className="rounded w-full flex flex-col sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center justify-between pb-4">
                <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg className="w-5 h-5 text-gray-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path>
                        </svg>
                    </div>
                    <input
                        type="text"
                        id="table-search"
                        className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 focus:ring-primary focus:border-primary"
                        placeholder="Search for items"
                        value={searchTerm}
                        onChange={handleSearch}
                    />
                </div>
            </div>
            <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs uppercase bg-primary text-white">
                <tr>
                    <th scope="col" className="px-6 py-3 text-left">
                        Name
                    </th>
                    <th scope="col" className="px-6 py-3 text-left">
                        Identification
                    </th>
                    <th scope="col" className="px-6 py-3 text-left">
                        Transactions
                    </th>
                    <th scope="col" className="px-6 py-3 text-left">
                        Points
                    </th>
                </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                {filteredMembers?.map((member, index) => (
                    <tr
                        key={index}
                        onClick={() => props.onMemberClick(member)}
                        className="cursor-pointer hover:bg-primary hover:text-white"
                    >
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                            {member.name ? member.name : "No Name"}
                        </th>
                        <td className="px-6 py-4">
                            {member.identification}
                        </td>
                        <td className="px-6 py-4">
                            {member.transactions}
                        </td>
                        <td className="px-6 py-4">
                            {member.points}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};
