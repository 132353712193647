import React, { useState, useEffect } from 'react';
import { Menu } from '@headlessui/react';
import OrganisationIcon from "../../assets/ic_organisation.svg";

const OrganisationDropdown = ({organisations, onOrganisationSelect }) => {
    const [selectedOrg, setSelectedOrg] = useState(organisations[0]);

    useEffect(() => {
        // Notify the parent component of the initial selected organisation
        onOrganisationSelect(organisations[0]);
    }, [organisations, onOrganisationSelect]);

    const handleSelect = (organisation) => {
        setSelectedOrg(organisation);
        onOrganisationSelect(organisation);
    };

    return (
        <div className="relative inline-block text-left">
            <Menu>
                {({ open }) => (
                    <>
                        <div>
                            <Menu.Button
                                className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary-500">
                                <img className="w-5 me-2" src={OrganisationIcon} alt="Organisation Icon"/>
                                {selectedOrg.name}
                                <svg
                                    className="w-5 h-5 ml-2 -mr-1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </Menu.Button>
                        </div>

                        <Menu.Items className="absolute left-0 md:right-0 w-56 mt-2 origin-top-left md:origin-top-right bg-white border border-gray-300 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {organisations.map((organisation) => (
                                <Menu.Item key={organisation.id}>
                                    {({ active }) => (
                                        <button
                                            className={`${
                                                active ? 'bg-primary-600 text-white' : 'text-gray-900'
                                            } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                            onClick={() => handleSelect(organisation)}
                                        >
                                            {organisation.name}
                                        </button>
                                    )}
                                </Menu.Item>
                            ))}
                        </Menu.Items>
                    </>
                )}
            </Menu>
        </div>
    );
};

export default OrganisationDropdown;
