import {FRAUD_PREVENTION_MAX_POINTS, FRAUD_PREVENTION_MAX_TRANSACTIONS} from "../modals/CampaignBuilderModal";
import {FRAUD_PREVENTION} from "../../pages/SingleCampaign";

export const FraudPreventionBuilder = ({missingValue, maxPoints, maxTransactions, onInputChange}) => {
    return <div className="mb-2 h-8 flex flex-row w-full justify-between">
        <div className="flex flex-row justify-start">
            <span className="h-8 p-2 text-xs self-center me-5">Max. points from campaign</span>
            <input
                className={`${missingValue === FRAUD_PREVENTION_MAX_POINTS ? "border border-red-600 " : ""} border-l  w-20 ps-2 text-xs border rounded h-8`}
                type="number"
                placeholder=""
                value={maxPoints}
                onChange={(event) => onInputChange(FRAUD_PREVENTION_MAX_POINTS ,FRAUD_PREVENTION, event.target.value)}/>
        </div>
        <div className="flex flex-row">
            <span className="h-8 p-2 text-xs self-center me-5">Max. transactions</span>
            <input
                className={`${missingValue === FRAUD_PREVENTION_MAX_TRANSACTIONS ? "border border-red-600 " : ""} border-l w-20 ps-2 text-xs border rounded h-8`}
                type="number"
                placeholder=""
                value={maxTransactions}
                onChange={(event) => onInputChange(FRAUD_PREVENTION_MAX_TRANSACTIONS, FRAUD_PREVENTION, event.target.value)}/>
        </div>
    </div>
}
