import {
    Dropdown,
    DROPDOWN_TYPE_REWARD_BASE, DROPDOWN_TYPE_REWARD_OPERATOR
} from "./Dropdow";
import {REWARD_POINTS} from "../../pages/SingleCampaign";

export const RewardBuilder = ({reward, onDelete, includeDeleteButton, onInputChange, missingValue, setSelected}) => {
    return <div className="mb-2 h-8 flex flex-row w-full justify-between">
        <div className="flex flex-row justify-between">
            <span className="h-8 p-2 text-xs self-center me-5">Add points with formula</span>
            <Dropdown initialValue={reward.rewardBase} className="border-r rounded-r-none" id={reward.id} setSelected={setSelected} dropdownType={DROPDOWN_TYPE_REWARD_BASE}/>
            <Dropdown initialValue={reward.operator} className="border-x-0 rounded-r-none rounded-l-none" id={reward.id} setSelected={setSelected} dropdownType={DROPDOWN_TYPE_REWARD_OPERATOR}/>
            <input
                className={`${missingValue === reward.id ? "border border-red-600 " : ""} border-l rounded-l-none w-20 ps-2 text-xs border rounded h-8`}
                type="number"
                placeholder=""
                value={reward.points}
                onChange={(event) => onInputChange(reward.id, REWARD_POINTS, event.target.value)}/>
        </div>
        <svg onClick={onDelete} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${!includeDeleteButton ? "hidden ": ""} cursor-pointer shadow-2xl ms-3 self-center min-w-min w-5 h-5`}>
            <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
        </svg>
    </div>
}
