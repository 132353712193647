import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {MembersTable} from "../components/members/MembersTable";
import {fetchMembersAction} from "../redux/actions/memberActions";

const Members = () => {
    const members = useSelector((state) => state.organisation?.currentOrganisation?.members);
    const [currentMember, setCurrentMember] = useState(null);

    const onMemberClick = (member) => {
        setCurrentMember(member);
    }

    const onMemberClose = () => {
        setCurrentMember(null);
    }

    return (
        <div className="main-section">
            <MembersTable onMemberClick={onMemberClick} members={members}/>
            {/*{currentMember ? <SingleCampaign member={currentMember} onClose={onCampaignClose}/> : <MembersTable onCampaignClick={onMemberClick} members={campaigns}/>}*/}
        </div>
    );
};

export default Members;
