import {useMemo, useEffect, useState} from "react";
import {Navigate, Outlet, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setInitialAuthState} from "./redux/actions/authActions";
import Navbar from "./components/shared/Navbar";
import Leftbar from "./components/shared/Leftbar";

const PrivateRoute = ({userData}) => {
    const isAuthenticated = useMemo(() => {
        return (userData, accessToken) => {
            return userData && accessToken;
        };
    }, []);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = localStorage.getItem("profile");
    const accessToken = JSON.parse(token)?.accessToken;

    useEffect(() => {
        if (!isAuthenticated(userData, accessToken)) {
            dispatch(setInitialAuthState(navigate));
        }
    }, [dispatch, navigate, userData, accessToken, isAuthenticated]);


    const [showLeftbar, setShowLeftbar] = useState(false);

    const toggleLeftbar = () => {
        setShowLeftbar(!showLeftbar);
    };

    return isAuthenticated(userData, accessToken) ? (
        <div className="scroll-smooth">
            <Navbar
                userData={userData}
                toggleLeftbar={toggleLeftbar}
                showLeftbar={showLeftbar}
            />

            <div className="md:mx-auto md:w-10/12 grid grid-cols-1 md:grid-cols-3 md:gap-6">
                <div className="col-span-1 md:col-span-1 w-full md:w-auto">
                    <Leftbar/>
                </div>
                <div className="ml-16 md:ml-0 col-span-2 w-full">
                    <Outlet/>
                </div>
            </div>
        </div>
    ) : (
        <Navigate to="/signin"/>
    );
};

export default PrivateRoute;
