import React, {useRef, useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {memo} from "react";
import {logoutAction} from "../../redux/actions/authActions";
import {IoLogOutOutline} from "react-icons/io5";
import {Transition} from "@headlessui/react";
import Logo from "../../assets/LoyalBaseLogo.png";
import CampaignBuilderModal from "../modals/CampaignBuilderModal";
import OrganisationDropdown from "./OrganisationDropdow";
import {HiOutlineSpeakerphone} from "react-icons/hi";
import OrganisationIcon from "../../assets/ic_organisation.svg";

const Navbar = ({userData}) => {
    const dispatch = useDispatch();
    const [loggingOut, setLoggingOut] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showCampaignBuilder, setShowCampaignBuilder] = useState(false);
    const organisations = useSelector((state) => state.organisation?.organisations);
    const currentOrganisationId = useSelector((state) => state.organisation?.currentOrganisation?._id);

    const dropdownRef = useRef(null);

    const handleProfileClick = () => {
        setShowDropdown(!showDropdown);
    };

    const logout = async () => {
        setLoggingOut(true);
        await dispatch(logoutAction());
        setLoggingOut(false);
    };


    const renderOrganisationIndicator = (currentOrganisationId, organisations) => {
        if (currentOrganisationId && organisations.length === 1) {
            const organisation = organisations.find(org => org._id === currentOrganisationId);
            if (organisation) {
                return <div className="rounded border px-2 py-1 flex flex-row">
                    <img className="w-5 me-2" src={OrganisationIcon} alt="Organisation Icon"/>
                    <p className="text-gray-700">{organisation.name}</p>
                </div>
            }
        } else if (currentOrganisationId && organisations.length > 1) {
            return (
                <OrganisationDropdown
                    currentOrganisationId={currentOrganisationId}
                    organisations={organisations}
                    onOrganisationSelect={() => {
                    }}
                />
            );
        }
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    return (
        <nav
            className="shadow-4xl border bg-componentBackground sticky top-0 z-20 mb-5 flex justify-center gap-10 p-2 md:items-center md:justify-between md:px-36">
            {showCampaignBuilder ? <CampaignBuilderModal closeModal={() => {
                setShowCampaignBuilder(false)
                //TODO show success snackbar
            }}/> : null}

            <div className="flex flex-row justify-start items-center">
                <Link to="/" className="hidden md:inline-block mr-4">
                    <img className="w-36" src={Logo} alt=""/>
                    {/*<HiChevronLeft/>*/}
                </Link>
            </div>
            <div className="relative flex justify-end md:w-130">
                <div className="me-8 self-center text-center">
                    {renderOrganisationIndicator(currentOrganisationId, organisations)}
                </div>
                <button
                    onClick={() => {
                        setShowCampaignBuilder(!showCampaignBuilder);
                    }}
                    className="shadow mr-20 text-white flex items-center bg-primary pt-1 pb-1 pl-2 pr-2 rounded cursor-pointer hover:bg-primary-600"
                >
                    <HiOutlineSpeakerphone className="text-xl mr-2"/>
                    <span className="hidden md:inline">Create Campaign</span>
                </button>

                <button
                    type="button"
                    className="shadow bg-primary inline-flex h-8 w-8 cursor-pointer items-center justify-center rounded-full"
                    onClick={handleProfileClick}>

                    <div className="content-center border h-8 w-8 rounded-full">
                        <p className="mt-0.5 text-white">{userData ? userData.email[0].toUpperCase() : null}</p>
                    </div>
                </button>
                <Transition
                    show={showDropdown}
                    enter="transition ease-out duration-100 transform"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="transition ease-in duration-75 transform"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    {() => (
                        <div
                            ref={dropdownRef}
                            className="absolute right-0 top-10 mt-2 w-72 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="user-menu"
                        >
                            <div className="py-1" role="none">
                                <div className="flex flex-col items-center">
                                    <div className="text-sm text-gray-500">{userData.email}</div>
                                </div>
                                <hr className="my-2"/>
                                <div className="flex justify-center">
                                    <button
                                        type="button"
                                        className="block w-full px-4 py-2  text-left text-sm text-red-400 hover:cursor-pointer hover:text-red-600"
                                        role="menuitem"
                                        onClick={logout}
                                        disabled={loggingOut}
                                    >
                                        {loggingOut ? (
                                            <div className="text-center">Logging out...</div>
                                        ) : (
                                            <div className="flex items-center justify-center">
                                                <span>Logout</span>
                                                <IoLogOutOutline className="ml-2"/>
                                            </div>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </Transition>
            </div>
        </nav>
    );
};

export default memo(Navbar)
