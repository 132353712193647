import { API, handleApiError } from "./utils";

const path = "campaigns";

export const createCampaign = async (ruleBuilder, currentOrganisationId) => {
  try {
    const res = await API.post(`/${path}/create-campaign`, {ruleBuilder, organisationId: currentOrganisationId}, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return { error: null, data: res.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const updateCampaign = async (ruleBuilder, currentOrganisationId) => {
  try {
    const res = await API.post(`/${path}/update-campaign`, {ruleBuilder, organisationId: currentOrganisationId}, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return { error: null, data: res.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const deleteCampaign = async (id, currentOrganisationId) => {
  try {
    const res = await API.post(`/${path}/delete-campaign`, {campaignId: id, organisationId: currentOrganisationId}, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return { error: null, data: res.data };
  } catch (error) {
    return handleApiError(error);
  }
};
export const fetchCampaigns = async (currentOrganisationId) => {
  try {
    const res = await API.get(`/${path}/fetch-campaigns?organisationId${currentOrganisationId}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return { error: null, data: res.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const submit = async (submission, currentOrganisationId) => {
  try {
    const res = await API.post(`/${path}/submit`, {submission, organisationId: currentOrganisationId} ,{
      headers: {
        "Content-Type": "application/json"
      },
    });
    return { error: null, data: res.data };
  } catch (error) {
    return handleApiError(error);
  }
};