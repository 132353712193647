export const CampaignTable = (props) => {

    return (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <div className="rounded w-full flex flex-col sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center justify-between pb-4">
                <label htmlFor="table-search" className="sr-only">Search</label>
                <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg className="w-5 h-5 text-gray-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path>
                        </svg>
                    </div>
                    <input
                        type="text"
                        id="table-search"
                        className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 focus:ring-primary focus:border-primary"
                        placeholder="Search for items"
                    />
                </div>
            </div>
            <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs uppercase bg-primary text-white">
                <tr>
                    <th scope="col" className="px-6 py-3 text-left">
                        Name
                    </th>
                    <th scope="col" className="px-6 py-3 text-left">
                        Status
                    </th>
                </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                {props.campaigns ? props.campaigns.map((campaign, index) => (
                    <tr
                        key={index}
                        onClick={() => props.onCampaignClick(campaign)}
                        className="cursor-pointer hover:bg-primary hover:text-white"
                    >
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                            {campaign.name}
                        </th>
                        <td className="px-6 py-4">
                            <button className={`${campaign.status === "running" ? "bg-primary text-white" : ""} ml-4 border px-2 py-1 rounded text-gray-900 hover:text-white hover:bg-primary`}>
                                {campaign.status}
                            </button>
                        </td>
                    </tr>
                )) : null}
                </tbody>
            </table>
        </div>
    );


    // return  <div className="w-full relative shadow-md sm:rounded-lg">
    //     <div className="rounded w-full flex flex-column sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center justify-between pb-4">
    //         <label htmlFor="table-search" className="sr-only">Search</label>
    //         <div className="relative">
    //             <div className="absolute inset-y-0 left-0 rtl:inset-r-0 rtl:right-0 flex items-center ps-3 pointer-events-none">
    //                 <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
    //             </div>
    //             <input type="text" id="table-search" className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 focus:ring-primary focus:border-primary dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for items"/>
    //         </div>
    //     </div>
    //     <table className="rounded w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
    //         <thead className="text-xs text-gray-700 uppercase">
    //         <tr>
    //             <th scope="col" className="px-6 py-3">
    //                 Name
    //             </th>
    //             <th scope="col" className="px-6 py-3">
    //                 Status
    //             </th>
    //         </tr>
    //         </thead>
    //         <tbody>
    //         {props.campaigns.map(campaign => {
    //             return <tr onClick={() => props.onCampaignClick(campaign)} className="cursor-pointer bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
    //                 <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
    //                     {campaign.name}
    //                 </th>
    //                 <td>
    //                     <button className={`${campaign.status === "running" ? " bg-primary ": ""} ms-4 border px-2 py-1 rounded text-white hover:bg-primary`}>{campaign.status}</button>
    //                 </td>
    //             </tr>
    //         })}
    //         </tbody>
    //     </table>
    // </div>

}