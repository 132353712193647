import React, {useState, useEffect} from "react";

export const DROPDOWN_TYPE_CONDITION_OPERATOR = "condition_operator"
export const DROPDOWN_TYPE_CONDITION_BASE = "condition_base"
export const DROPDOWN_TYPE_METRIC = "condition_metric"
export const DROPDOWN_TYPE_REWARD_BASE = "reward_base"
export const DROPDOWN_TYPE_REWARD_OPERATOR = "reward_operator"

export const conditionBaseValues = ["Transaction value", "Total last month", "Total last week", "Total today"]
export const operatorValues = ["greater than", "less than", "equals to"]
export const metricValues = ["m²", "€", "$"]
export const mathematicalOperationValues = ["+", "-", "*", "/"]
export const rewardBaseValues = ["Transaction value", "Total last month", "Total last week", "Total today"]
export const Dropdown = (props) => {
    const [open, setOpen] = useState(false);
    const getInitialSelectionState = () => {
        let selectedDropdownValue = ""

        if (props.initialValue) {
            selectedDropdownValue = props.initialValue;
        } else if (props.dropdownType === DROPDOWN_TYPE_CONDITION_BASE) {
            selectedDropdownValue = conditionBaseValues[0];
        } else if (props.dropdownType === DROPDOWN_TYPE_CONDITION_OPERATOR) {
            selectedDropdownValue = operatorValues[0];
        } else if (props.dropdownType === DROPDOWN_TYPE_METRIC) {
            selectedDropdownValue =  metricValues[0];
        } else if (props.dropdownType === DROPDOWN_TYPE_REWARD_BASE) {
            selectedDropdownValue =  rewardBaseValues[0];
        } else if (props.dropdownType === DROPDOWN_TYPE_REWARD_OPERATOR) {
            selectedDropdownValue = mathematicalOperationValues[0];
        }

        return selectedDropdownValue;
    }

    const [selected, setSelected] = useState(getInitialSelectionState());

    const renderValues = () => {
        if (props.dropdownType === DROPDOWN_TYPE_CONDITION_BASE) {
            return <>
                {conditionBaseValues.map(value => {
                    return <li>
                        <a onClick={() => {
                            setSelected(value)
                            props.setSelected(props.id, props.dropdownType, value)
                            setOpen(false)
                        }} href="#" className="text-xs block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{value}</a>
                    </li>
                })}
            </>
        }else if (props.dropdownType === DROPDOWN_TYPE_CONDITION_OPERATOR) {
            return <>
                {operatorValues.map(value => {
                    return <li>
                        <a onClick={() => {
                            setSelected(value)
                            props.setSelected(props.id, props.dropdownType, value)
                            setOpen(false)
                        }} href="#" className="text-xs block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{value}</a>
                    </li>
                })}
            </>
        } else if (props.dropdownType === DROPDOWN_TYPE_METRIC) {
            return <>
                {metricValues.map(value => {
                    return <li>
                        <a onClick={() => {
                            setSelected(value)
                            props.setSelected(props.id, props.dropdownType, value)
                            setOpen(false)
                        }} href="#" className="text-xs block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{value}</a>
                    </li>
                })}
            </>
        } else if (props.dropdownType === DROPDOWN_TYPE_REWARD_BASE) {
            return <>
                {rewardBaseValues.map(value => {
                    return <li>
                        <a onClick={() => {
                            setSelected(value)
                            props.setSelected(props.id, props.dropdownType, value)
                            setOpen(false)
                        }} href="#" className="text-xs block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{value}</a>
                    </li>
                })}
            </>
        } else if (props.dropdownType === DROPDOWN_TYPE_REWARD_OPERATOR) {
            return <>
                {mathematicalOperationValues.map(value => {
                    return <li>
                        <a onClick={() => {
                            setSelected(value)
                            props.setSelected(props.id, props.dropdownType, value)
                            setOpen(false)
                        }} href="#" className="text-xs block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{value}</a>
                    </li>
                })}
            </>
        }
    }

    return (
        <div>
            <button onClick={()=> setOpen(!open)} id="dropdownDefaultButton" data-dropdown-toggle="dropdown" className={`${props.className} max-w-fit whitespace-nowrap text-xs h-8 border text-black font-medium rounded px-5 py-2.5 text-center inline-flex items-center`} type="button">{selected}<svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
            </svg>
            </button>

            <div id="dropdown" className={`${!open ? "hidden" : ""} w-fit fixed z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700`}>
                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                    {renderValues()}
                </ul>
            </div>
        </div>
    )
}