import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import Feature from "../components/home/Feature";

const Home = () => {
    const userData = useSelector((state) => state.auth?.userData);

    return (
        <div className="w-full flex justify-center items-center">
            <div>
                <nav className="bg-gray-800 py-4">
                    <div className="container mx-auto flex justify-between items-center">
                        <a href="#" className="text-white text-xl font-bold">Your Logo</a>
                        {userData ? <Link className="text-white border rounded px-2 hover:bg-primary"
                                          to={`/campaigns`}>App</Link> : <Link to={`/signin`}>Sign In</Link>}
                    </div>
                </nav>

                <section className="bg-blue-900 py-20 text-white">
                    <div className="container mx-auto text-center">
                        <h1 className="text-4xl font-bold">Welcome to Your Website</h1>
                        <p className="mt-4 text-lg">A catchy tagline describing your business or website.</p>
                        <button
                            className="mt-8 bg-white text-blue-900 py-2 px-6 rounded-lg font-semibold hover:bg-blue-100">Get
                            Started
                        </button>
                    </div>
                </section>

                <section className="py-16">
                    <div className="container mx-auto">
                        <Feature left/>
                        <Feature/>
                        <Feature left/>
                        <Feature/>
                        <Feature left/>
                        <Feature/>
                    </div>
                </section>

                <footer className="bg-gray-800 text-white py-8">
                    <div className="container mx-auto text-center">
                        <p>&copy; 2024 Your Website. All rights reserved.</p>
                    </div>
                </footer>
            </div>
        </div>
    );
};

export default Home;
