import {useSelector} from "react-redux";

export const Analytics = () => {
    const campaigns = useSelector((state) => state.organisation?.currentOrganisation?.campaigns);

    return <div className="main-section bg-primary">
        <div className="p-4 flex flex-col w-full justify-start">
            {campaigns.map(campaign => {
                return <div className="self-start mt-4 flex flex-col w-full p-4 text-center bg-white border border-gray-200 rounded-lg shadow">
                    <span className="self-start">{campaign.name}</span>
                    <div className="mt-1 flex flex-row">
                        {/*<Badge text="" className="bg-primary text-white"/>*/}
                    </div>
                    <span className="ms-4 mt-2 self-start text-sm">Submissions</span>
                    {campaign.submissions.map(submission => {
                        <p>{submission.id}</p>
                    })}
                </div>;
            })}
        </div>
    </div>
}