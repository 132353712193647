import {
  ADD_CAMPAIGN, DELETE_CAMPAIGN,
  FETCH_ORGANISATIONS,
  FETCH_SINGLE_ORGANISATION,
  UPDATE_CAMPAIGN, UPDATE_ORGANISATION
} from "../constants/organisationConstants";

const initialState = {
  organisations: [],
  currentOrganisation: null
};

const organisationReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_ORGANISATIONS: {
      return {
        ...state,
        organisations: payload.organisations
      };
    }
    case FETCH_SINGLE_ORGANISATION: {
      let newOrganisations = [...state.organisations];
      newOrganisations = newOrganisations.filter(org => org._id !== payload.organisation._id);
      newOrganisations.push(payload.organisation)

      return {
        ...state,
        currentOrganisation: payload.organisation,
        organisations: newOrganisations
      };
    }
    case ADD_CAMPAIGN: {
      // Create a new campaigns array with the new campaign at the top
      const newCampaigns = [...state.currentOrganisation.campaigns];
      newCampaigns.unshift(payload.campaign);

      state.currentOrganisation.campaigns = newCampaigns;

      // Create a new currentOrganisation object with the updated campaigns
      const updatedCurrentOrganisation = {
        ...state,
        campaigns: newCampaigns,
      };

      // Create a new organisations array with the updated currentOrganisation
      const newOrganisations = state.organisations.map(org => {
        if (org._id === state.currentOrganisation._id) {
          return { ...org, campaigns: newCampaigns };
        }
        return org;
      });

      return {
        ...state,
        currentOrganisation: updatedCurrentOrganisation,
        organisations: newOrganisations,
      };
    }
    case UPDATE_CAMPAIGN: {
      if (state.currentOrganisation._id === action.payload.organisationId) {
        // Filter out the campaign if it exists and then add it to the top
        const newCampaigns = [
          action.payload.campaign,
          ...state.currentOrganisation.campaigns.filter(
              campaign => campaign._id !== action.payload.campaign._id
          ),
        ];

        // Update the current organisation
        state.currentOrganisation.campaigns = newCampaigns;

        // Update the corresponding organisation in the list of organisations
        const newOrganisations = state.organisations.map(org => {
          if (org._id === state.currentOrganisation._id) {
            return {...org, campaigns: newCampaigns};
          }
          return org;
        });

        return {
          ...state,
          organisations: newOrganisations,
        };
      }
      return state;
    }
    case UPDATE_ORGANISATION: {
      const updatedOrganisation = payload.organisation
      state.currentOrganisation = updatedOrganisation

      const newOrganisations = state.organisations.filter(org => org._id !== state.currentOrganisation._id);
      newOrganisations.push(updatedOrganisation);

      return {
        ...state,
        organisations: newOrganisations
      }
    }
    case DELETE_CAMPAIGN: {
      const newCampaigns = [...state.currentOrganisation.campaigns].filter(campaign => campaign._id !== payload.campaignId);

      state.currentOrganisation.campaigns = newCampaigns;

      const newOrganisations = [...state.organisations];
      newOrganisations.forEach(org => {
        if (org._id === state.currentOrganisation._id) {
          org.campaigns = newCampaigns
        }
      })

      return  {
        ...state,
        currentOrganisation: state.currentOrganisation,
        organisations: newOrganisations
      }

    }

    default:
      return state;
  }
};

export default organisationReducer;
