import {FETCH_MEMBERS} from "../constants/memberConstants";

const initialState = {
  members: []
};

const memberReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_MEMBERS: {
      state.members = payload.members
      return {
        ...state
      };
    }

    default:
      return state;
  }
};

export default memberReducer;
