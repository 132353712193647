import {useState} from "react";
import {Link} from "react-router-dom";

export const CampaignMenu = (props) => {
    const [open, setOpen] = useState(false)

    return <div className="self-end w-fit ps-8 z-10">
        <button onClick={() => setOpen(!open)} className="w-fit p-2 text-sm font-medium text-center dark:text-gray-900 bg-white rounded hover:bg-gray-100" type="button">
            <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 4 15">
                <path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"/>
            </svg>
        </button>
        <div className={`${!open ? "hidden" : ""} absolute z-10 bg-white divide-y divide-gray-100 ${!props.showSubmissionDialog ? "shadow" : ""}`}>
            <ul className={`border text-gray-900 text-sm dark:text-gray-200 ${props.showSubmissionDialog ? "rounded-e" : "rounded"}`}>
                <li onClick={() => {
                    props.setShowSubmissionDialog(!props.showSubmissionDialog);
                }} className={`cursor-pointer text-gray-900 p-4 border-b flex flex-row hover:bg-gray-100 dark:hover:bg-gray-600 hover:text-white`}>
                    <span className="self-center cursor-pointerblock">Send submission</span>
                </li>
                <Link
                    onClick={() => {
                        props.openSubmissions(props.campaign._id)
                    }}
                    className="flex flex-row text-gray-900 cursor-pointer p-4 border-b hover:bg-gray-100 dark:hover:bg-gray-600 hover:text-white"
                    to="/analytics">

                    <p>Submissions</p>
                </Link>
                <li onClick={async () => {
                    await navigator.clipboard.writeText(props.campaign._id);
                    props.showMessage("Copied to clipboard!");
                    setOpen(false);
                }} className="flex flex-col text-gray-900 p-4 border-b cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-600 hover:text-white">
                    <span className="self-start">Copy Campaign Id</span>
                    <span className="text-xs ">{props.campaign._id}</span>
                </li>
            </ul>
        </div>
    </div>
}