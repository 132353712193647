import { combineReducers } from "redux";

import authReducer from "./authReducer";
import userReducer from "./userReducer";
import memberReducer from "./memberReducer";
import organisationReducer from "./organisationReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  member: memberReducer,
  organisation: organisationReducer,
});

export default rootReducer;
